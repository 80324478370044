import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import firebaseApp, { db } from "../../firebase";
import { AuthContext } from "components/context/Auth";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const Approval = () => {
  const { user, userData, subscription, userLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [submitted, setSubmitted] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const [generatedEmails, setGeneratedEmails] = useState({});
  const [generatingEmail, setGeneratingEmail] = useState();

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // Get a single user's email
  const generateEmail = async (id) => {
    setGeneratingEmail(id);

    let email;

    await db
      .collection("users")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          email = doc.data().email;
          setGeneratedEmails({ ...generatedEmails, [id]: email });
        }
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    setGeneratingEmail(false);
    return;
  };

  const getUserEmails = async (ids) => {
    let userIds = [];
    let userEmails = [];

    ids.forEach((id) => {
      const users = rows.find((row) => row.id === id).users;

      userIds.push(...users);
    });

    await db
      .collection("users")
      .where("uid", "in", userIds)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          userEmails.push(doc.data().email);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    return userEmails;
  };

  const approveLocations = async () => {
    if (!selected.length) return;

    setLoading("approve");
    setSubmitted();

    let batch = db.batch();

    selected.forEach((id) => {
      batch.set(db.collection("locations").doc(id), { pending: false }, { merge: true });
    });

    const emails = await getUserEmails(selected);

    emails.forEach((email) => {
      batch.set(db.collection("emails").doc(), {
        to: [email],
        message: {
          subject: "Your HLTHDSK Listing Has Been Approved",
          html: `<p>Your HLTHDSK listing has been approved and is now visible for patients to see on the map.</p><p>You can update the current estimated waiting time for your clinic and more from the <a href="https://${window.location.host}/dashboard">HLTHDSK dashboard</a>.</p>`,
        },
      });
    });

    batch.commit().then(() => {
      const updatedRows = rows.filter((row) => !selected.includes(row.id));

      setRows(updatedRows);
      setSelected([]);

      setLoading(false);
      setSubmitted("The selected locations have been approved.");
    });
  };

  const rejectLocations = async () => {
    if (!selected.length) return;

    setLoading("reject");
    setSubmitted();

    let batch = db.batch();

    selected.forEach((id) => {
      batch.set(db.collection("locations").doc(id), { pending: "rejected" }, { merge: true });
    });

    const emails = await getUserEmails(selected);

    emails.forEach((email) => {
      batch.set(db.collection("emails").doc(), {
        to: [email],
        message: {
          subject: "Your HLTHDSK Listing Has Been Rejected",
          html: `<p>Your HLTHDSK listing has been rejected and will not be visible for patients to see on the map.</p><p>If you think this decision has been made in error, please contact us at info@hlthdsk.com.</p>`,
        },
      });
    });

    batch.commit().then(() => {
      const updatedRows = rows.filter((row) => !selected.includes(row.id));

      setRows(updatedRows);
      setSelected([]);

      setLoading(false);
      setSubmitted("The selected locations have been rejected.");
    });
  };

  useEffect(() => {
    db.collection("locations")
      .where("pending", "==", true)
      .get()
      .then((querySnapshot) => {
        let docs = [];

        querySnapshot.forEach(function (doc) {
          if (doc.data()) {
            docs.push(doc.data());
          }
        });

        setRows(docs);
        setDataLoaded(true);
      });
  }, []);

  // A cap of 30 locations to be emailed at one time due to a Firebase limitation
  // https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_and_array-contains-any
  useEffect(() => {
    if (selected.length > 30) {
      setError("A maximum of 30 locations can be approved at once.");
    } else {
      if (error) setError();
    }
  }, [selected]);

  if (userData && !userData?.admin) navigate("/dashboard");

  if (userData?.admin)
    return (
      <Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" component="h2" sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}>
            Approval
          </Typography>

          <Typography variant="body" sx={{ display: "block", maxWidth: "40rem" }}>
            Approve Urgent Care locations and allow them to appear on the map.
          </Typography>

          {submitted ? (
            <Alert severity="success" sx={{ mt: 3, mb: 4 }}>
              {submitted}
            </Alert>
          ) : null}
        </Box>

        {rows.length > 0 ? (
          <Box>
            <TableContainer component={Paper} sx={{ mb: 4 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={selected.length > 0 && selected.length < rows.length}
                        checked={rows.length > 0 && selected.length === rows.length}
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                    <TableCell>Clinic Name</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>ZIP</TableCell>
                    <TableCell>Map</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    const isItemSelected = selected.includes(row.id);

                    return (
                      <TableRow
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>{row.address}</TableCell>
                        <TableCell>{row.city}</TableCell>
                        <TableCell>{row.state}</TableCell>
                        <TableCell>{row.zip}</TableCell>
                        <TableCell>
                          <a
                            target="_blank"
                            href={`https://www.google.com/maps/place/${row.lat},${row.lng}`}
                          >
                            Link
                          </a>
                        </TableCell>
                        <TableCell>
                          {generatedEmails[row.id] ? (
                            generatedEmails[row.id]
                          ) : (
                            <LoadingButton
                              size="small"
                              variant="contained"
                              loading={generatingEmail === row.id}
                              disabled={!!generatingEmail}
                              onClick={() => generateEmail(row.id)}
                            >
                              Get Email
                            </LoadingButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <LoadingButton
              disabled={!selected.length || !!error || !!loading}
              variant="contained"
              loading={loading === "approve"}
              onClick={approveLocations}
              size="large"
              sx={{ display: "block", mb: 3 }}
            >
              Approve Selected Locations
            </LoadingButton>

            <LoadingButton
              disabled={!selected.length || !!error || !!loading}
              variant="contained"
              color="error"
              loading={loading === "reject"}
              onClick={rejectLocations}
              size="large"
              sx={{ display: "block" }}
            >
              Reject Selected Locations
            </LoadingButton>

            {error ? (
              <FormHelperText error sx={{ mt: 2 }}>
                {error}
              </FormHelperText>
            ) : null}
          </Box>
        ) : dataLoaded ? (
          <Alert severity="info">You're all caught up!</Alert>
        ) : (
          <CircularProgress size={30} />
        )}
      </Box>
    );
};

export default Approval;
